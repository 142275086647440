<template>
  <div class="container-fluid">
    <div class="card">
      <h3 class="card-header">User's Health Report</h3>
      <div class="card-body" v-show="showInputUser">
        <form>
          <div class="form-row">
            <div class="col-md-3">
              Nama
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valName}"
                v-model="nama"
                placeholder="enter nama"
              />
            </div>
            <div class="col-md-3">
              No Telepon
              <input
                type="number"
                class="form-control"
                :class="{'is-valid': valPhone}"
                v-model="nomor_telepon"
                placeholder="enter telepon"
              />
            </div>
            <div class="col-md-3">
              Tanggal Lahir
              <!--<input type="date" :format='customdate' class="form-control" v-model="birth_date" placeholder="masukkan"> {{birth_date}} -->
              <datepicker
                type="text"
                id="date"
                class="form-control"
                :class="{'is-valid': valLahir}"
                v-model="birth_date"
              ></datepicker>
            </div>
            <div class="col-md-3">
              Alamat Email
              <input
                type="email"
                class="form-control"
                :class="{'is-valid': valEmail}"
                v-model="email"
                placeholder="enter email"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <button class="btn btn-success pull-right" type="button" v-on:click="addUser">tambah</button>
            </div>
          </div>
        </form>
      </div>
      <div class="card-body">
        Cari berdasarkan nama atau email
        
          <div class="form-row">
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="cari berdasarkan nama atau email lalu enter"
                @change="searchName"
                v-model="inputSearch"
              />
            </div>
          </div>
      </div>
      <div class="card-body" v-show="showPagination">
        <div class="icon-container" @click="previousPage">
          <span class="ti-angle-double-left"></span>
          <span class="icon-name"> Halaman: {{currentPage+1}}. Per halaman : {{maxRow}} </span>
        </div>
        <div class="icon-container align-right" @click="nextPage">
          <span class="ti-angle-double-right"></span>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-striped table-bordered table-hover">
            <thead>
              <tr class="table-info">
                <th class="text-center">No.</th>
                <th class="text-center">Nama</th>
                <th class="text-center">No Telepon</th>
                <th class="text-center">Email</th>
                <th class="text-center">Tanggal Lahir</th>
                <th class="text-center">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(user,key,index) in userDataShow" :key="key">
                <td class="text-center">{{currentPage*maxRow+index+1 }}</td>
                <td class="text-center">{{user.nama }}</td>
                <td class="text-center">{{user.nomor_telepon }}</td>
                <td class="text-center">{{user.email }}</td>
                <td class="text-center">{{user.birth_date }}</td>
                <td class="text-center">
                  <button class="btn btn-sm btn-warning" @click="viewData(key)">view</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    v-on:click="editusermodal(key)"
                  >edit</button>
                  <!-- <button type="button" class="btn btn-sm btn-danger" v-on:click="deleteUser(key)">delete
                  </button>-->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- Modal edit-->

    <div
      class="modal fade"
      data-backdrop="false"
      id="edit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Edit User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <label>Nama</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditName}"
                v-model="editnama"
                placeholder="update username"
              />
              <br />
              <label>No telepon</label>
              <input
                type="number"
                class="form-control"
                :class="{'is-valid': valEditPhone}"
                v-model="editnomor_telepon"
                placeholder="update telepon"
              />
              <br />
              <label>Email</label>
              <input
                type="email"
                class="form-control"
                :class="{'is-valid': valEditEmail}"
                v-model="editemail"
                placeholder="update email"
              />
              <br />
              <label>Birth date</label>
              <!-- <datepicker id="date" class="form-control" v-model="editbirth_date"></datepicker> -->
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="updateUser">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal view -->

    <div
      class="modal fade"
      data-backdrop="false"
      id="view"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewLabel">
              Data User :
              <b>{{Viewnama}}</b>
            </h5>
            <button type="button" class="close" aria-label="Close">
              <span aria-hidden="true" @click="closeData">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <div class="form-row">
                <div class="col-md-4">
                  <label>Nama</label>
                  <span class="form-control">{{Viewnama}}</span>
                </div>
                <div class="col-md-8">
                  <label>Address</label>
                  <span class="form-control">{{Viewaddress}}</span>
                </div>
                <div class="col-md-3">
                  <label>Birth Date</label>
                  <span class="form-control">{{Viewbirth_date}}</span>
                </div>
                <div class="col-md-3">
                  <label>Gender</label>
                  <span class="form-control">{{Viewgender}}</span>
                </div>
                <div class="col-md-3">
                  <label>Username</label>
                  <span class="form-control">{{Viewusername}}</span>
                </div>
                <div class="col-md-3">
                  <label>Phone Number</label>
                  <span class="form-control">{{Viewnomor_telepon}}</span>
                </div>
                <div class="col-md-4">
                  <label>Email</label>
                  <span class="form-control">{{Viewemail}}</span>
                </div>
                <!-- <div class="col-md-4">
                  <label>Wallet Id</label>
                  <span class="form-control">{{Viewwallet_id}}</span>
                </div> -->
                <!-- <div class="col-md-4">
                  <label>Wearable Id</label>
                  <span class="form-control">{{Viewwearable_id}}</span>
                </div> -->
                <div class="col-md-6">
                  <label>NIK</label>
                  <span class="form-control">{{nik}}</span>
                </div>
              </div>
              <div class="form-row">
                <div class="col-md-12">
                  <hr />
                  <center>
                    <h5>Health Overview</h5>
                  </center>
                  <table class="table table-hover">
                    <thead class="thead-light">
                      <tr>
                        <th scope="col">No</th>
                        <th scope="col">Censor</th>
                        <th scope="col">Value</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">1</th>
                        <td>Bmi</td>
                        <td>{{bmi}}</td>
                      </tr>
                      <tr>
                        <th scope="row">2</th>
                        <td>Dia</td>
                        <td>{{dia}}</td>
                      </tr>
                      <tr>
                        <th scope="row">3</th>
                        <td>Glucose</td>
                        <td>{{glucose}}</td>
                      </tr>
                      <tr>
                        <th scope="row">4</th>
                        <td>Height</td>
                        <td>{{height}}</td>
                      </tr>
                      <tr>
                        <th scope="row">5</th>
                        <td>Weight</td>
                        <td>{{weight}}</td>
                      </tr>
                      <tr>
                        <th scope="row">6</th>
                        <td>Spo2</td>
                        <td>{{spo2}}</td>
                      </tr>
                      <tr>
                        <th scope="row">7</th>
                        <td>Sys</td>
                        <td>{{sys}}</td>
                      </tr>
                      <tr>
                        <th scope="row">8</th>
                        <td>Temperature</td>
                        <td>{{temperature}}</td>
                      </tr>
                      <tr>
                        <th scope="row">9</th>
                        <td>Pulse</td>
                        <td>{{pulse}}</td>
                      </tr>
                      <tr>
                        <th scope="row">10</th>
                        <td>Chol</td>
                        <td>{{chol}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </form>
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn btn-secondary"
              data-dismiss="modal"
              @click="closeData"
            >Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";
import moment from "moment";
import jquery from "jquery-ui";
import Datepicker from "vuejs-datepicker";

export default {
  name: "user",
  components: {
    Datepicker
  },

  data() {
    return {
      isAdmin: false,
      isFaskes: false,
      emailUser: null,
      listDevice: null,

      maxRow: 20,
      currentPage: 0,
      paginationData: {},
      showPagination: true,

      inputSearch: "",

      showInputUser: false,
      nama: "",
      nomor_telepon: "",
      email: "",
      birth_date: new Date(),

      editnama: [],
      editnomor_telepon: [],
      editemail: [],
      editbirth_date: [],

      userData: null,
      user: [],
      userDataShow: null,
      totalData: 0,

      // ---------View Data user-------
      Viewaddress: null,
      Viewbirth_date: null,
      Viewemail: null,
      nik: null,
      Viewgender: null,
      Viewhealth_overview: null,
      Viewnama: null,
      Viewnomor_telepon: null,
      Viewusername: null,
      Viewwallet_id: null,
      Viewwearable_id: null,

      // -------View data overview----------
      bmi: null,
      dia: null,
      glucose: null,
      height: null,
      pulse: null,
      spo2: null,
      sys: null,
      temperature: null,
      weight: null,
      chol: null,
      // -------View data overview----------

      editKey: null
    };
  },

  computed: {
    // ------- validasi add --------
    valName() {
      if (this.nama.length === 0 || this.nama.length > 20) {
        return false;
      }
      return true;
    },
    valPhone() {
      if (this.nomor_telepon.length === 0 || this.nomor_telepon.length > 20) {
        return false;
      }
      return true;
    },
    valEmail() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        return true;
      }
      return false;
    },

    valLahir() {
      if (this.birth_date.length === 0) {
        return false;
      }
      return true;
    },

    // ------- validasi edit --------
    valEditName() {
      if (this.editnama.length === 0 || this.editnama.length > 20) {
        return false;
      }
      return true;
    },
    valEditPhone() {
      if (
        this.editnomor_telepon.length === 0 ||
        this.editnomor_telepon.length > 20
      ) {
        return false;
      }
      return true;
    },
    valEditEmail() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.editemail)) {
        return true;
      }
      return false;
    }
  },

  methods: {
    searchName(){
      if(this.inputSearch!=null && this.inputSearch.length!=0){
        if(this.userData!=null && this.userData.length!=0){
          this.userDataShow = {};
          for (let key in this.userData) {
            var name = this.userData[key].nama 
            var email = this.userData[key].email 
            name = name.toLowerCase();
            email = email.toLowerCase();
            var searchInputLower = this.inputSearch.toLowerCase();
            if(name.includes(searchInputLower) || email.includes(searchInputLower)){
              this.userDataShow[key] = this.userData[key];
            }
          }
          this.showPagination = false;
          this.currentPage = 0;
          return;
        }
      }
      this.userDataShow = this.userData;
      this.totalData = this.getMapSize(this.userData);
      this.showPagination = true;
      this.setPaginationData();
    },
    addUser() {
      this.user.push(
        this.nama,
        this.nomor_telepon,
        this.email,
        this.birth_date
      );
      if (this.valName && this.valPhone && this.valEmail) {
        firebase
          .database()
          .ref("user")
          .push({
            nama: this.nama,
            nomor_telepon: this.nomor_telepon,
            email: this.email,
            birth_date: moment(this.birth_date).format("DD/MM/YY")
          });
        this.nama = "";
        this.nomor_telepon = "";
        this.email = "";
        this.birth_date = new Date();
      }
    },

    deleteUser(key) {
      // firebase.database().ref('user/'+key).remove();
    },

    editusermodal(userKey) {
      $("#edit").modal("show");
      firebase
        .database()
        .ref("user/" + userKey)
        .on("value", snapshot => {
          var res = snapshot.val();
          this.editnama = res.nama;
          this.editemail = res.email;
          this.editbirth_date = moment(res.birth_date).format("DD/MM/YY");
          this.editnomor_telepon = res.nomor_telepon;
          this.editKey = userKey;
        });
    },

    updateUser() {
      if (this.valEditName && this.valEditPhone && this.valEditEmail) {
        $("#edit").modal("hide");
        firebase
          .database()
          .ref("user/" + this.editKey)
          .update({
            nama: this.editnama,
            nomor_telepon: this.editnomor_telepon,
            email: this.editemail,
            birth_date: moment(this.editbirth_date).format("DD/MM/YY")
          });
        this.editnama = "";
        this.editnomor_telepon = "";
        this.editemail = "";
        this.editbirth_date = "";
      }
    },

    viewData(userKey) {
      for (const [key, value] of Object.entries(this.userData)) {
        if (userKey === key) {
          var res = value;
          this.Viewaddress = res.address;
          this.Viewbirth_date = res.birth_date;
          this.Viewemail = res.email;
          this.nik = res.user_nik;
          this.Viewgender = res.gender;
          this.Viewhealth_overview = res.health_overview;
          this.Viewnama = res.nama;
          this.Viewnomor_telepon = res.nomor_telepon;
          this.Viewusername = res.username;

          var dataoverview = res.health_overview;
          if (dataoverview != undefined) {
            this.bmi = dataoverview.bmi;
            this.dia = dataoverview.dia;
            this.glucose = dataoverview.glucose;
            this.height = dataoverview.height;
            this.pulse = dataoverview.pulse;
            this.spo2 = dataoverview.spo2;
            this.sys = dataoverview.sys;
            this.temperature = dataoverview.temperature;
            this.weight = dataoverview.weight;
            this.chol = dataoverview.chol;
            this.editKey = userKey;
          }
          break;
        }
      }
      $("#view").modal("show");
    },
    closeData() {
      $("#view").modal("hide");
    },

    fetchFirebaseData() {
      firebase
        .database()
        .ref("user")
        .on("value", snapshot => {
          if(this.isAdmin){
            this.userData = snapshot.val();
            this.totalData = this.getMapSize(this.userData);
            this.setPaginationData();
          }else{
            this.checkingHealthData(snapshot.val());
          }
        });
    },
    checkingHealthData(allUsers) {
      firebase
        .database()
        .ref("atm_sehat/log_checking")
        .once("value", snapshot => {
          var arr = snapshot.val();
          
          this.processData(arr,allUsers);
        });
    },
    processData(arr,allUsers){
      var faskes = this.isFaskes;
      
      var listUser = {};
      var listDeviceId = this.listDevice;
      
      Object.keys(arr).forEach(function(key) {
        var idDevice = arr[key].id_device
        if(faskes!=null && faskes && listDeviceId.length!=0){
          
          if(listDeviceId.includes(idDevice)){
            
            var userId = arr[key].user_id;
            if(userId!=null){
              
              var userDataTemp = allUsers[userId];
              if(userDataTemp==null){
                
                //data is from NIK
                listUser[userId] = {
                  "nama":userId
                };
              }else{
                listUser[userId] = userDataTemp;
              }
              
            }
          }
        }
      });
      this.userData = listUser;
      this.totalData = this.getMapSize(listUser);
      this.setPaginationData();
    },
    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      this.emailUser = currentUser.email;
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
            this.isFaskes = res.web_faskes;
            
            if(this.isFaskes){
              this.retrieveDevice();
            }else if(this.isAdmin){
              this.fetchFirebaseData();
            }
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    },
    retrieveDevice(){
        firebase
          .database()
          .ref("atm_sehat/device")
          .orderByChild("faskesEmail")
          .equalTo(this.emailUser)
          .on("value", snapshot => {
            var deviceData = snapshot.val();
            var listDeviceId = [];
            Object.keys(deviceData).forEach(function(key) {
              listDeviceId.push(deviceData[key].identifier);
            });
            this.listDevice = listDeviceId;
            this.fetchFirebaseData()
          });
    },
    getMapSize(x) {
        var len = 0;
        for (var count in x) {
                len++;
        }

        return len;
    },
    previousPage(){
      var page = this.currentPage;
      if(page<1){
        page = 0;
      }else{
        page -= 1;
      }
      this.currentPage = page;
      this.userDataShow = this.paginationData[page];
    },
    nextPage(){
      var page = this.currentPage;
      var size = this.totalData
      if((page+1)*this.maxRow<size){
        page+=1;
      }
      this.currentPage=page;
      this.userDataShow = this.paginationData[page];
    },
    setPaginationData(){
      var index = 0;
      var counter = 0;
      var maxRowData = this.maxRow;
      var data = this.userData;
      var dataPerPage = {};
      var result = {};
      for (var key in data) {
        if(counter<(index+1)*maxRowData){
          dataPerPage[key] = data[key];
        }
        if(counter==this.totalData-1 || counter>=(index+1)*maxRowData){
          result[index] = dataPerPage;
          index++;
          dataPerPage = {}
          dataPerPage[key] = data[key]; 
        }
        counter++;
      }
      this.paginationData = result;
      this.userDataShow = this.paginationData[0];
    }
  },

  created() {
    this.retrieveUser()
  }
};
</script>

<style>
#date {
  border: none !important;
}
</style>