<template>
<div class="container signup-container">
      <div class="row">
        <div class="col-md-6 ads">
          <h1><span id="fl">WELCOME</span><span id="sl"></span></h1>
        </div>
        <div class="col-md-6 signup-form">
          
            
          <h3>Sign Up</h3>
          <form>
            <div class="form-group">
              <input type="text" class="form-control" v-model="email" name="email" placeholder="Email">
            </div>
            <div class="form-group">
              <input type="password" class="form-control" v-model="password" name="password" placeholder="Password">
            </div>
            <div class="form-group">
              <button type="button" class="btn btn-success btn-lg btn-block" @click=signUp>Sign-Up</button>
            </div>
            <div class="form-group forget-password">
               <center> <a href="/login">Login </a> </center>
            </div>
          </form>
        </div>
      </div>
    </div>
</template>

<script >
    import firebase from 'firebase';
    export default {
    name: 'signUp',
    data() {
        return  {
        email:'',
        password:''
    }
    },
    methods: {
        signUp:function() {
            firebase.auth().createUserWithEmailAndPassword(this.email, this.password).then(function(user){
                    alert('akun sudah dibuat.')
                },
                function(err) {
                    alert('opss'+ err.message)
                }
            );
        }
    }
    }
</script>

<style>
    body {
  font-family: 'Montserrat', sans-serif;
  transition: 3s;
}

.signup-container {
  margin-top: 10%;
  border: 1px solid #CCD1D1;
  border-radius: 5px;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 30px 0 rgba(0, 0, 0, 0.19);
  max-width: 50%;
}

.ads {
  background-color: #A569BD;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  color: #fff;
  padding: 10px;
  text-align: center;
}
.ads {
    background-color: #85C1E9 ;

}

.ads h1 {
  margin-top: 20%;
}

#fl {
  font-weight: 600;
}

#sl {
  font-weight: 100 !important;
}

.profile-img {
  text-align: center;
}

.profile-img img {
  border-radius: 50%;
  /* animation: mymove 2s infinite; */
}

@keyframes mymove {
  from {border: 1px solid #F2F3F4;}
  to {border: 8px solid #F2F3F4;}
}

.signup-form {
  padding: 15px;
}

.signup-form h3 {
  text-align: center;
  padding-top: 15px;
  padding-bottom: 15px;
}

.form-control {
  font-size: 14px;
}

.forget-password a {
  font-weight: 500;
  text-decoration: none;
  font-size: 14px;
}
</style>

