  <template>
  <div>
    <div class="row">
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-info">
                    <i class="ti-dashboard"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <P>Scale</P>
                    {{scale}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-success">
                    <i class="ti-ruler"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Suhu</p>
                    {{suhu}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-danger">
                    <i class="ti-pulse"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Oxi Meter</p>
                    {{oxiMeter}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-info">
                    <i class="ti-hand-stop"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Glukosa</p>
                    {{glukosa}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-success">
                    <i class="ti-tablet"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Cholestrol</p>
                    {{cholesterol}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-4">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-danger">
                    <i class="ti-heart"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Blood Pressure</p>
                    {{bpm}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>Data</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import { StatsCard, ChartCard } from "@/components/index";
import Chartist from "chartist";
import firebase from "firebase";

export default {
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      isAdmin: false,
      isFaskes: false,
      emailUser: null,
      listDevice: null,

      bpm: 0,
      scale: 0,
      suhu: 0,
      oxiMeter: 0,
      glukosa: 0,
      cholesterol: 0,

      satbpm: null,
      satscale: null,
      satsuhu: null,
      satoxiMeter: null,
      satglukosa: null,
      satcholesterol: null,

      alldata: null
    };
  },

  created() {
    this.retrieveUser();
  },

  methods: {
    checkingHealthData() {
      firebase
        .database()
        .ref("atm_sehat/log_checking")
        .once("value", snapshot => {
          var arr = snapshot.val();
          this.processData(arr);
        });
    },
    processData(arr){
      var res = [];
      var satuan = [];
      var faskes = this.isFaskes;
      var deviceOwener = this.listDevice;
      
      Object.keys(arr).forEach(function(key) {
        var datahealth = JSON.parse(arr[key].data);

        var idDevice = arr[key].id_device
        if(faskes!=null && faskes && deviceOwener.length!=0){
          if(!deviceOwener.includes(idDevice)){
            return;
          }
        }
        var sensorName = datahealth.sensorName;

        if (sensorName == "Glucose and Cholesterol") {
          var value = datahealth.value;
          if (value[0].value == 0) {
            sensorName = value[1].name;
            // console.log(value[1].name)
            // console.log(value[1].name+" : "+value[1].value+" "+value[1].unit)
          } else {
            sensorName = value[0].name;
            // console.log(value[0].name+" : "+value[0].value+" "+value[0].unit)
          }
        } else {
        }
        var sensorName = sensorName.replace(/\s+/g, "_");
        res[sensorName] = res[sensorName] == null || Number.isNaN(res[sensorName])
          ? 1
          : res[sensorName] + 1;

        var unit = datahealth.value;
        var sat = unit[0].unit;
        satuan[sensorName] = sat;
      });

      this.satbpm = satuan.Blood_Pressure_Monitor;
      this.satsuhu = satuan.Suhu;
      this.satscale = satuan.Scale;
      this.satoxiMeter = satuan.OxiMeter;
      this.satglukosa = satuan.Glukosa;
      this.satcholesterol = satuan.Cholesterol;

      // console.log(satuan)

      this.bpm += res.Blood_Pressure_Monitor==null?0:res.Blood_Pressure_Monitor;
      this.suhu += res.Suhu==null?0:res.Suhu;
      this.scale += res.Scale==null?0:res.Scale;
      this.oxiMeter += res.OxiMeter==null?0:res.OxiMeter;
      this.glukosa += res.Glukosa==null?0:res.Glukosa;
      this.cholesterol += res.Cholesterol==null?0:res.Cholesterol;

      var dat = "";
      Object.keys(res).forEach(function(key) {
        var countallsensor = res[key];
        dat += +countallsensor + " " + key + "";
      });
      this.alldata = dat;
      // console.log(this.alldata)
    },
    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      this.emailUser = currentUser.email;
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
            this.isFaskes = res.web_faskes;
            
            if(this.isFaskes){
              this.retrieveDevice();
            }else if(this.isAdmin){
              this.checkingHealthData();
            }
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    },
    retrieveDevice(){
      if(this.isFaskes){
        firebase
          .database()
          .ref("atm_sehat/device")
          .orderByChild("faskesEmail")
          .equalTo(this.emailUser)
          .on("value", snapshot => {
            var deviceData = snapshot.val();
            var listDeviceId = [];
            Object.keys(deviceData).forEach(function(key) {
              listDeviceId.push(deviceData[key].identifier);
            });
            this.listDevice = listDeviceId;
            this.checkingHealthData();
          });
      }
    }
  }
};
</script>


<style>
</style>
