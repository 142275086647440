<template>
  <div class="container login-container">
    <div class="row">
      <div class="col-md-4 y">
        <img id="g" src="../assets/img/atmlogo.png" />
        <div class="form-group col-md-12">
          <h5>
            Hai
            <br />Selamat Datang Kembali.
          </h5>
        </div>
        <form class="a col-md-12">
          <div class="form-group">
            <input
              id="input"
              type="text"
              class="form-control input-logo1"
              v-model="email"
              name="email"
              placeholder="Alamat email"
            />
          </div>

          <div class="form-group">
            <input
              id="input"
              type="password"
              class="form-control input-logo2"
              v-model="password"
              name="password"
              placeholder="Password"
              @keyup.enter="login"
            />
          </div>

          <div class="form-group">
            <label id="pwfont">Lupa password?</label>
            <button
              type="button"
              id="btn-1"
              class="btn-sm col-md-5 btn-primary btn-block float-right"
              @click="login"
            >
              <b>Login</b>
            </button>
          </div>
          <!--  <div class="form-group">
               <center> <router-link to="signup" >Create account </router-link> </center>  
          </div>-->
        </form>
      </div>
      <div class="col-md-8 login-form">
        <img id="banner" src="../assets/img/Banner.png" />
        <img id="atmfont" src="../assets/img/atmfont.png" />
        <p id="visi">
          Through ATM Sehat, we invite people to have long term
          <br />helth investment.
        </p>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="modal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="modalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Dashboard Atm Sehat</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">Selamat datang di dashboard controler ATM Sehat</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" @click="masuk">oke</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->

    <!-- Modal -->
    <div
      class="modal fade"
      id="exampleModal"
      tabindex="-1"
      role="dialog"
      aria-labelledby="exampleModalLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content modal-sm">
          <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">Dashboard Atm Sehat</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">Email dan Password Anda Salah</div>
          <div class="modal-footer">
            <button type="button" class="btn btn-primary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>


 <script>
import firebase from "firebase";
import $ from "jquery";

export default {
  name: "login",
  data() {
    return {
      email: "",
      password: ""
    };
  },

  methods: {
    login: function() {
      firebase
        .auth()
        .signInWithEmailAndPassword(this.email, this.password)
        .then(
          user => {
            this.checkUserAccess(this.email);
          },
          err => {
            $("#exampleModal").modal("show");
          }
        );
    },

    masuk() {
      $("#modal").modal("hide");
      this.$router.replace("dashboard");
      var k = "selamat datang";
    },

    checkUserAccess(email) {
      var lower_email = email.toLowerCase();
      lower_email = lower_email.replaceAll(".", "X2E").replaceAll("@", "X40");
    
      firebase
        .database()
        .ref("user")
        .child(lower_email)
        .on("value", snapshot => {
          var userData = snapshot.val();
          if (userData.email == email) {
            if (userData.web_admin || userData.web_faskes) {
              $("#modal").modal("show");
            }else{
              userHasNoAccess();
              $("#exampleModal").modal("show");
            }
          }
          // var list_user = snapshot.val();
          // var verifiedUser = false;
          // Object.keys(list_user).forEach(function(key) {
          //   if (list_user[key].email == email) {
          //     if (list_user[key].web_admin) {
          //       verifiedUser = true;
          //     }
          //   }
          // });
          // if (!verifiedUser) {
            
          // } else {
          //   $("#modal").modal("show");
          // }
        });
    },
    userHasNoAccess() {
      firebase
        .auth()
        .signOut()
        .then(() => {
          this.$router.replace("login");
          alert("User has no access");
        });
    }
  }
};
</script>


<style>
body {
  font-family: "Montserrat", sans-serif;
  transition: 3s;
}

.login-container {
  margin-top: 5%;
  margin-bottom: 5%;
  border: 1px solid #ccd1d1;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 10px 0 rgba(0, 0, 0, 0.19);
  max-width: 90%;
}

.y {
  border-top-left-radius: 6px;
  border-bottom-left-radius: 6px;
  padding: 10px;
}

.y h5 {
  font-size: 22px;
  font-weight: 800;
}

.a {
  margin-bottom: 30%;
}

#g {
  margin-top: 5%;
  margin-bottom: 30%;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30%;
}

#banner {
  margin-top: 5%;
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}

#atmfont {
  padding: 5px;
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 35%;
}

#visi {
  font-size: 15px;
  text-align: center;
  font-weight: 300;
  color: #ffffff;
}

#input {
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2), 0 4px 9px 0 rgba(0, 0, 0, 0.17);
  border-radius: 6px;
  background-color: #f0f7f2;
  padding-left: 40px;
}

.input-logo1 {
  background: url(../assets/img/Iconemail.png) no-repeat 5%;
  background-size: 15px;
  padding-left: 20px;
}

.input-logo2 {
  background: url(../assets/img/Iconpassword.png) no-repeat 5%;
  background-size: 15px;
  padding-left: 20px;
}

@keyframes mymove {
  from {
    border: 1px solid #f2f3f4;
  }
  to {
    border: 8px solid #f2f3f4;
  }
}

.login-form {
  background-color: #1d67de;
  padding: 15px;
}

.form-control {
  font-size: 14px;
  border: 4px solid #ccd1d1;
}

#btn-1 {
  border-radius: 8px;
  box-shadow: 0 3px 3px 0 rgba(0, 0, 0, 0.3), 0 2px 4px 0 rgba(0, 0, 0, 0.17);
  background-color: #1d67de;
}

#pwfont {
  font-size: 13px;
  font-weight: 800;
  color: #1d67de;
}
</style>

