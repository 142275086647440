import Vue from "vue";
import App from "./App";
import router from "./router/index";
import firebase from "firebase";

import PaperDashboard from "./plugins/paperDashboard";
import "vue-notifyjs/themes/default.css";

import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';

Vue.use(PaperDashboard);

let app ='';

// var config = {
//     apiKey: "AIzaSyCJnVcQ5p_MarKIKbwP5-ntZ6dWxADto5A",
//     authDomain: "tea-app-40fa3.firebaseapp.com",
//     databaseURL: "https://tea-app-40fa3.firebaseio.com",
//     projectId: "tea-app-40fa3",
//     storageBucket: "tea-app-40fa3.appspot.com",
//     messagingSenderId: "500701190283",
//     appId: "1:500701190283:web:724dded7c4eccad9ffdc8e"
//   };
  var config = {
    apiKey: "AIzaSyAVzfEMceuJbxVltwmjCSCSUW3RmlBwC94",
    authDomain: "telehealtheasyaccess.firebaseapp.com",
    databaseURL: "https://telehealtheasyaccess.firebaseio.com",
    projectId: "telehealtheasyaccess",
    storageBucket: "telehealtheasyaccess.appspot.com",
    messagingSenderId: "800203062043",
    appId: "1:800203062043:web:3138226d0cfaace404b1be"
  };
firebase.initializeApp(config);

var db_realtime = firebase.database();

window.db=db_realtime;



/* eslint-disable no-new */
// new Vue({
//   router,
//   render: h => h(App)
// }).$mount("#app");


firebase.auth().onAuthStateChanged(() => {
  if (!app) {
    /* eslint-disable no-new */
    app = new Vue({
      router,
      render: h => h(App)
    }).$mount('#app');
  }
});