<template>
  <div class="container-fluid">
    <div class="card">
      <h3 class="card-header">Banner Atm Sehat</h3>
      <div class="card-body">
        <form>
          <div class="form-row">
            <div class="col">
              <input
                type="text"
                class="form-control"
                :class="{'is-valid':valUrl}"
                v-model="url"
                placeholder="enter url http//...com"
              />
            </div>
            <button class="btn btn-success" type="button" @click="addbanner">Add Link</button>
          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead class="table-info">
              <tr>
                <th class="text-center">Link</th>
                <th class="text-center">Aksi</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(banner,key) in bannerData" :key="key">
                <td class="text-center">{{banner.url}}</td>

                <td class="text-center">
                  <button class="btn btn-sm btn-warning" @click="modalBanner(key)">view</button>
                  <button type="button" class="btn btn-sm btn-primary" @click="modaledit(key)">Edit</button>
                  <button
                    type="button"
                    class="btn btn-sm btn-danger"
                    @click="modaldelete(key)"
                  >Delete</button>
                  <!-- <button type="button" class="btn btn-sm btn-danger" v-on:click='deleteDevice(key)'>hapus </button> -->
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- modal view -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="view"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewLabel">
              <b>image :</b>
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="Viewurl" alt="image" class="img-thumbnail rounded mx-auto d-block" />
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Small modal delete -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="delete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Delete Banner</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>apakah anda ingin menghapus data ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-sm btn-secondary" data-dismiss="modal">cancle</button>
            <button type="button" class="btn-sm btn-danger" @click="deletek">Delete Data</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal edit -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="edit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Edit url</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <label>Url</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid':valEditUrl}"
                v-model="editurl"
                placeholder="update url"
              />
              <br />
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="updateurl">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";

export default {
  name: "banner",

  data() {
    return {
      url: "",
      Viewurl: null,
      editurl: [],

      countdata: null,

      banners: [],
      bannerData: null,

      dlKey: null
    };
  },

  computed: {
    //------------Validasi Add --------------
    valUrl() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (pattern.test(this.url)) {
        return true;
      }
      return false;
    },

    //------------Validasi Edit --------------
    valEditUrl() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );
      if (pattern.test(this.editurl)) {
        return true;
      }
      return false;
    }
  },

  methods: {
    addbanner() {
      this.banners.push(this.url);
      if (this.valUrl) {
        firebase
          .database()
          .ref("banner/home")
          .push({
            url: this.url
          });
        this.url = "";
      }
    },

    modaldelete(newKey) {
      $("#delete").modal("show");
      this.dlKey = newKey;
    },
    deletek() {
      $("#delete").modal("hide");
      firebase
        .database()
        .ref("banner/home/" + this.dlKey)
        .remove();
    },

    modaledit(newKey) {
      $("#edit").modal("show");
      firebase
        .database()
        .ref("banner/home/" + newKey)
        .on("value", snapshot => {
          var res = snapshot.val();
          this.editurl = res.url;
          this.dlKey = newKey;
        });
    },

    updateurl() {
      if (this.valEditUrl) {
        $("#edit").modal("hide");
        firebase
          .database()
          .ref("banner/home/" + this.dlKey)
          .update({
            url: this.editurl
          });
        this.editurl = "";
      }
    },

    modalBanner(newKey) {
      $("#view").modal("show");
      firebase
        .database()
        .ref("banner/home/" + newKey)
        .on("value", snapshot => {
          var res = snapshot.val();
          this.Viewurl = res.url;
        });
    },

    fetchFirebaseData() {
      firebase
        .database()
        .ref("banner/home")
        .on("value", snapshot => {
          var bannerData = snapshot.val();
          this.bannerData = bannerData;
        });
    }
  },

  created() {
    this.fetchFirebaseData();
  }
};
</script>
