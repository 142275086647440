<template>
  <div class="wrapper">
    <side-bar>
      <template slot="links">
        <sidebar-link to="/dashboard" name="dashboard report" icon="ti-panel icon-primary"/>
        <sidebar-link to="/dashboardhealth" name="dashboard Health" icon="ti-pie-chart icon-primary"/>
        <sidebar-link to="/dashboardatm" name="dashboard ATM Sehat" icon="ti-view-list-alt icon-primary"/>
        <sidebar-link to="/device" name="atm sehat device" icon="ti-mobile  icon-warning"/>
        <sidebar-link to="/user" name="atm sehat user" icon="ti-mobile  icon-warning"/>
        <sidebar-link v-show="isAdmin" to="/mnguser" name="Web User Management" icon="ti-user"/>
        <sidebar-link v-show="isAdmin" to="/medicine" name="medicine" icon="ti-unlink"  />
        <sidebar-link v-show="isAdmin" to="/article" name="Article" icon="ti-write"/>
        <sidebar-link v-show="isAdmin" to="/banner" name="Banner" icon="ti-credit-card"/>
      </template>
      <!-- mobile fsd -->
      <mobile-menu>
        <li class="nav-item">
          <a class="nav-link">
            <i class="ti-panel"></i>
            <p>Stats</p>
          </a>
        </li>
        <li class="nav-item">
          <a href="#" class="nav-link" @click="logout" >
            <i class="ti-angle-right"></i>
            <p>Logout</p>
          </a>
        </li>
        <li class="divider"></li>
      </mobile-menu>
    </side-bar>
    <div class="main-panel">
      <top-navbar></top-navbar>

      <dashboard-content @click.native="toggleSidebar">

      </dashboard-content>

      <content-footer></content-footer>
    </div>
  </div>
</template>
<style lang="scss">
</style>

<script>
import firebase from 'firebase';
import TopNavbar from "./TopNavbar.vue";
import ContentFooter from "./ContentFooter.vue";
import DashboardContent from "./Content.vue";
import MobileMenu from "./MobileMenu";
export default {
  data() {
    return {
      isAdmin: false
    };
  },
  components: {
    TopNavbar,
    ContentFooter,
    DashboardContent,
    MobileMenu
  },
  methods: {
    toggleSidebar() {
      if (this.$sidebar.showSidebar) {
        this.$sidebar.displaySidebar(false);
      }
    },
    logout: function() {
      firebase.auth().signOut().then(() => {
      this.$router.replace('login')
      alert('Berhasil Keluar')
      })
    },
    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      if(currentUser==null){
        return;
      }
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    }
  },
  created() {
    this.retrieveUser();
  }
};
</script>
