import DashboardLayout from "@/layout/dashboard/DashboardLayout.vue";
// GeneralViews
import NotFound from "@/pages/NotFoundPage.vue";

//loginpage
import Login from "@/pages/Login.vue";
import SignUp from "@/SignUp.vue";

import firebase from "firebase";

// Admin pages
import Dashboard from "@/pages/Dashboard.vue";
import DashboardHealth from "@/pages/Dashboardhealth.vue";
import DashboardAtm from "@/pages/DashboardATMsehat.vue"
import Device from "@/pages/Device.vue";
import User from "@/pages/User.vue";

import Notifications from "@/pages/Notifications.vue";
import Medicine from "@/pages/Medicine.vue";
import Mnguser from "@/pages/Mnguser.vue";
import Article from "@/pages/Article.vue";
import Banner from  "@/pages/Banner.vue";



const routes = [
    {
      path: '*',
      redirect:'/login'
    },
    {
      path: '/',
      redirect:'/login'
    },
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {
      path: '/signup',
      name: 'signUp',
      component: SignUp
    },
    {
      path: "/dashboard",
      component: DashboardLayout,
      redirect: "/dashboard",
      meta: {
        requiresAuth: true
      },
      
      children: [
      {
        path: '/dashboard',
        name: 'dashboard',
        component: Dashboard
      },
      {
        path: '/dashboardhealth',
        name: 'Dashboard Health',
        component: DashboardHealth
      },
      {
        path: '/dashboardatm',
        name: 'Dashboard ATM Sehat',
        component: DashboardAtm
      },         
      {
        path: "/device",
        name: "device",
        component: Device
      },
       {
        path: "/user",
        name: "user",
        component: User
      },
      {
        path: "/notifications",
        name: "notifications",
        component: Notifications
      },
      {
        path: "/medicine",
        name: "medicine",
        component: Medicine
      },

      {
        path: "/article",
        name: "article",
        component: Article
      },
      {
        path: "/mnguser",
        name: "mnguser",
        component: Mnguser
      },
       {
        path: "/banner",
        name: "banner",
        component: Banner
      }
      ]},
       
  { path: "*", component: NotFound }
];

/**
 * Asynchronously load view (Webpack Lazy loading compatible)
 * The specified component must be inside the Views folder
 * @param  {string} name  the filename (basename) of the view to load.
function view(name) {
   var res= require('../components/Dashboard/Views/' + name + '.vue');
   return res;
};**/

export default routes;
