<template>
  <div class="container-fluid">
    <div class="card" v-show="isAdmin">
      <h3 class="card-header">Add ATM Sehat Deviece</h3>
      <div class="card-body">
        <form>
          <div class="form-row">
            <!-- <div class="col">
            <input type="text" class="form-control" v-model="connected_id_user" placeholder="enter id"> </div>-->
            <div class="col">
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valName}"
                v-model="name"
                placeholder="enter name"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valIdentifier}"
                v-model="identifier"
                placeholder="enter identifier"
              />
            </div>
            <div class="col">
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valKey}"
                v-model="key"
                placeholder="enter key"
              />
            </div>
            <div class="col">
              <select class="form-control" v-model="status">
                <option disabled value>status</option>
                <option selected="Admin" value="Active">Active</option>
                <option value="Not-Active">Not-Active</option>
              </select>
            </div>
            <button class="btn btn-success" type="button" @click="addDevice">Add Device</button>
          </div>
        </form>
      </div>
    </div>

    <card>
      <h3 class="card-header">Device Report Atm Sehat</h3>
      
      <div class="card-body">
        Search by Nama Alat
        
          <div class="form-row">
            <div class="col">
              <input
                type="text"
                class="form-control"
                placeholder="Cari berdasarkan nama alat lalu tekan enter"
                @change="searchName"
                v-model="inputSearch"
              />
            </div>
          </div>
        
      </div>
      <div class="card-body" v-show="showPagination">
        <div class="icon-container" @click="previousPage">
          <span class="ti-angle-double-left"></span>
          <span class="icon-name"> Halaman: {{currentPage+1}}. Per halaman : {{maxRow}} </span>
        </div>
        <div class="icon-container align-right" @click="nextPage">
          <span class="ti-angle-double-right"></span>
        </div>
      </div>
      <div class="table-responsive">
        <table class="table table-bordered table-hover">
          <thead class="table-info">
            <tr>
              <th class="text-center">No.</th>
              <th class="text-center">Nama Alat</th>
              <th class="text-center">Identifier</th>
              <th class="text-center">Key</th>
              <th class="text-center">Fasilitas Kesehatan</th>
              <th class="text-center">Status</th>
              <th class="text-center">Jumlah Data</th>
              <th class="text-center">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(device,key,index) in deviceDataShow" :key="key">
              <td class="text-center">{{currentPage*maxRow+index+1 }}</td>
              <td class="text-left">{{ device.name }}</td>
              <td class="text-center">{{ device.identifier }}</td>
              <td class="text-center">{{ device.key }}</td>
              <td class="text-center">{{ device.faskesEmail }}</td>
              <td class="text-center">{{ device.status }}</td>
              <td class="text-center">{{ totalPerDevice[key]}}</td>
              <td class="text-center">
                <button
                  type="button"
                  class="btn btn-sm btn-primary"
                  v-show="isAdmin"
                  @click="editDevicemodal(key)"
                >Edit</button>
                <br />
                <downloadexcel
                  type="button"
                  v-if="dataPerdevice[key]!=undefined"
                  class="btn btn-sm btn-success"
                  :data="dataPerdevice[key]"
                  :fields="json_fields"
                  worksheet="My Worksheet"
                  name="data.xls"
                >Download Excel</downloadexcel>
                <!-- <button type="button" class="btn btn-sm btn-danger" @click='downloadExcel(key)'>download data</button> -->
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card>

    <!-- modal edit -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="edit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Edit User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <!-- <label>Id</label>  -->
              <!-- <input type="text" class="form-control" v-model="editconnected_id_user" placeholder="enter id"><br> -->
              <label>Name</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditName}"
                v-model="editname"
                placeholder="enter name"
              />
              <br />
              <label>Key</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditKey}"
                v-model="editkey"
                placeholder="enter key"
              />
              <br />
              <label>Faskes Email</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditFaskesEmail}"
                v-model="editFaskesEmail"
                placeholder="enter faskes email (optional)"
              />
              <br />
              <label>Status</label>
              <select class="form-control" v-model="editstatus">
                <option disabled value>status</option>
                <option value="Active">Active</option>
                <option value="Not-Active">Not-Active</option>
              </select>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
            <button type="button" class="btn btn-primary" v-on:click="updateDevice">Save changes</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";
import downloadexcel from "vue-json-excel";
import md5 from "md5";

export default {
  name: "device",
  components: {
    downloadexcel
  },
  data() {
    return {
      isAdmin: false,
      userEmail: null,
      connected_id_user: "",
      name: "",
      identifier: "",
      key: "",
      status: "Active",
      title: [],

      maxRow: 20,
      currentPage: 0,
      paginationData: {},
      showPagination: true,
      totalData: 0,

      inputSearch: "",

      editconnected_id_user: [],
      editname: [],
      editidentifier: [],
      editkey: [],
      editFaskesEmail: null,
      editstatus: [],

      deviceData: null,
      deviceDataShow: null,
      userData: null,
      devices: [],

      totalPerDevice: [],
      dataPerdevice: [],
      json_fields: {
        "Waktu Pengecekan": "time",
        "User ID": "userId",
        "Jenis Kelamin": "jenisKelamin",
        "Sensor Name": "sensor",
        "Hasil Pengecekan": "value"
      }
    };
  },

  computed: {
    // ------- validasi add --------
    valName() {
      if (this.name.length === 0 || this.name.length > 30) {
        return false;
      }
      return true;
    },
    valKey() {
      if (this.key.length === 0 || this.key.length > 30) {
        return false;
      }
      return true;
    },

    valIdentifier() {
      if (this.identifier.length === 0) {
        return false;
      }
      return true;
    },

    // ------- validasi edit --------
    valEditName() {
      if (this.editname.length === 0 || this.editname.length > 30) {
        return false;
      }
      return true;
    },
    valEditKey() {
      if (this.editkey.length === 0 || this.editkey.length > 30) {
        return false;
      }
      return true;
    },

    valEditIdentifier() {
      if (this.editidentifier.length === 0) {
        return false;
      }
      return true;
    },

    valEditFaskesEmail(){
      if(this.editFaskesEmail == null || this.editFaskesEmail.length==0){
        this.editFaskesEmail = null;
        return true;
      }
      else{
        var valid = String(this.editFaskesEmail)
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
        return valid;
      }
    }
  },

  methods: {
    searchName(){
      if(this.inputSearch!=null && this.inputSearch.length!=0){
        if(this.deviceData!=null && this.deviceData.length!=0){
          this.deviceDataShow = {};
          for (let key in this.deviceData) {
            var name = this.deviceData[key].name 

            name = name.toLowerCase();
            
            var searchInputLower = this.inputSearch.toLowerCase();
            if(name.includes(searchInputLower)){
              this.deviceDataShow[key] = this.deviceData[key];
            }
          }
          this.showPagination = false;
          this.currentPage = 0;
          return;
        }
      }
      this.deviceDataShow = this.deviceData;
      this.showPagination = true;
      this.setPaginationData();
    },
    addDevice() {
      this.devices.push(
        this.connected_id_user,
        this.name,
        this.identifier,
        this.key,
        this.status
      );
      if (this.valName && this.valKey && this.valIdentifier) {
        // ------------- set key == nama dari identifier ---------------------
        firebase
          .database()
          .ref("atm_sehat/device")
          .child(this.identifier)
          .set({
            connected_id_user: "",
            name: this.name,
            identifier: this.identifier,
            key: this.key,
            status: this.status
          });
        this.connected_id_user = "";
        this.name = "";
        this.identifier = "";
        this.key = "";
        this.status = "Active";
        alert("berhasil ditambah");
      }
    },

    deleteDevice(key) {
      firebase
        .database()
        .ref("atm_sehat/device/" + key)
        .remove();
    },
    downloadExcel(key) {
      var data = this.dataPerdevice;
      if (data[key] === undefined) {
        alert("Data tidak tersedia");
      } else {
      }
    },
    editDevicemodal(userKey) {
      if(this.isAdmin){
        $("#edit").modal("show");
        firebase
          .database()
          .ref("atm_sehat/device/" + userKey)
          .on("value", snapshot => {
            var res = snapshot.val();
            this.editname = res.name;
            this.editidentifier = res.identifier;
            this.editkey = res.key;
            this.editstatus = res.status;
            this.editFaskesEmail = res.faskesEmail
          });
      }
    },
    updateDevice(userKey) {
      if (this.valEditName && this.valEditKey && this.valEditIdentifier && this.valEditFaskesEmail) {
        if(this.editFaskesEmail!=null){
          firebase
            .database()
            .ref("user")
            .orderByChild("email")
            .equalTo(this.editFaskesEmail)
            .once("value", snapshot => {
              if(snapshot.val() != null){
                this.updateDeviceData();
              }else{
                alert("Email tidak terdaftar");
              }
          });
        }else{
          this.updateDeviceData();
        }
        
      }
    },
    updateDeviceData(){
      $("#edit").modal("hide");
      //-------------- update key child -------------
      //console.log("edit identifier: "+this.editidentifier)
      firebase
        .database()
        .ref("atm_sehat/device/")
        .child(this.editidentifier)
        .update({
          name: this.editname,
          key: this.editkey,
          status: this.editstatus,
          faskesEmail: this.editFaskesEmail
        });
      this.editconnected_id_user = "";
      this.editname = "";
      this.editidentifier = "";
      this.editkey = "";
      this.editstatus = "";
      this.editFaskesEmail = "";
      alert("data berhasil disimpan");
    },
    checking() {
      firebase
        .database()
        .ref("atm_sehat/log_checking")
        .once("value", snapshot => {
          this.logdata = snapshot.numChildren();
          var arr = snapshot.val();
          var res = [];
          var counter = 0;
          var resMilis = [];
          var dataPerdevice = [];
          var userData = this.userData;
          const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
       
          Object.keys(arr).forEach(function(key) {
            res[arr[key].id_device] = (res[arr[key].id_device]==null) ? 1 : res[arr[key].id_device] + 1;
            if (resMilis[arr[key].id_device] === undefined) {
              resMilis[arr[key].id_device] = [];
            }
            if (dataPerdevice[arr[key].id_device] === undefined) {
              dataPerdevice[arr[key].id_device] = [];
            }
            var dataTemp = JSON.parse(arr[key].data);
            var d = new Date(arr[key].milis);
            var healthData = "";
            dataTemp.value.forEach(element => {
              healthData += element.value + " " + element.unit + "; ";
            });
            
            var jk = "";
            if(arr[key].user_id!=undefined && userData[arr[key].user_id]!=undefined ){
              jk = userData[arr[key].user_id].gender;
            }
            dataPerdevice[arr[key].id_device].push({
              time: d.toLocaleString('id-ID', options), 
              userId: md5(arr[key].user_id + ""),
              jenisKelamin: jk,
              sensor: dataTemp.sensorName,
              value: healthData
            });
            resMilis[arr[key].id_device].push(arr[key].milis);
            counter += 1;
          });
          this.totalPerDevice = res;

          this.dataPerdevice = dataPerdevice;
        });
    },

    fetchDeviceData() {
      if(!this.isAdmin){
        firebase
          .database()
          .ref("atm_sehat/device")
          .orderByChild("faskesEmail")
          .equalTo(this.userEmail)
          .on("value", snapshot => {
            this.deviceData = snapshot.val();
            this.totalData = this.getMapSize(this.deviceData);
            this.setPaginationData();
          });
      }else{
        firebase
          .database()
          .ref("atm_sehat/device")
          .on("value", snapshot => {
            this.deviceData = snapshot.val();            
            this.totalData = this.getMapSize(this.deviceData);
            this.setPaginationData();
          });
      }
    },

    fetchUserData() {
      firebase
        .database()
        .ref("user")
        .on("value", snapshot => {
          var temp = snapshot.val();
          this.userData = temp;
          this.checking();
        });
    },
    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      this.userEmail = currentUser.email;
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
            this.fetchDeviceData();
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    },
    getMapSize(x) {
        var len = 0;
        for (var count in x) {
                len++;
        }

        return len;
    },
    previousPage(){
      var page = this.currentPage;
      if(page<1){
        page = 0;
      }else{
        page -= 1;
      }
      this.currentPage = page;
      this.deviceDataShow = this.paginationData[page];
    },
    nextPage(){
      var page = this.currentPage;
      var size = this.totalData
      if((page+1)*this.maxRow<size){
        page+=1;
      }
      this.currentPage=page;
      this.deviceDataShow = this.paginationData[page];
    },
    setPaginationData(){
      var index = 0;
      var counter = 0;
      var maxRowData = this.maxRow;
      var data = this.deviceData;
      var dataPerPage = {};
      var result = {};
      for (var key in data) {
        if(counter<(index+1)*maxRowData){
          dataPerPage[key] = data[key];
        }
        if(counter==this.totalData-1 || counter>=(index+1)*maxRowData){
          result[index] = dataPerPage;
          index++;
          dataPerPage = {}
          dataPerPage[key] = data[key]; 
        }
        counter++;
      }
      this.paginationData = result;
      this.deviceDataShow = this.paginationData[0];
    }
  },
  created() {
    this.retrieveUser()
    this.fetchUserData();
  }
};
</script>

