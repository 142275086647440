<template>
  <div class="container-fluid">
    <div class="card">
      <h3 class="card-header">Management Article</h3>
      <div class="card-body">
        <form>
          <div class="form-row">
            <div class="col-md-5">
              Title
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valTitle}"
                v-model="title"
                placeholder="enter title"
              />
            </div>
            <div class="col-md-4">
              Author
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valAuthor}"
                v-model="author"
                placeholder="enter author"
              />
            </div>
            <div class="col-md-3">
              Category
              <select class="form-control" v-model="category">
                <option>General Health</option>
                <option>Nutrition</option>
              </select>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-3">
              Update time
              <br />
              <span class="form-control">{{ date | moment }}</span>
            </div>
            <div class="col-md-5">
              Link
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valUrl}"
                v-model="link"
                placeholder="enter link"
              />
            </div>
            <div class="col-md-4">
              Image url
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valPhoto}"
                v-model="photo"
                placeholder="enter image url"
              />
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              Text Area
              <textarea
                rows="5"
                class="form-control"
                :class="{'is-valid': valText}"
                v-model="text"
              ></textarea>
            </div>
          </div>
          <div class="form-row">
            <div class="col-md-12">
              <button
                class="btn btn-success pull-right"
                type="button"
                v-on:click="addArticle"
              >tambah</button>
            </div>
          </div>
        </form>
      </div>
    </div>

    <div class="card">
      <table class="table table-striped table-bordered table-hover">
        <thead class="table-info">
          <tr>
            <th class="text-center">Title</th>
            <th class="text-center">Author</th>
            <th class="text-center">Category</th>
            <th class="text-center">Update Time</th>
            <th class="text-center">Aksi</th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(article,key) in articlesData" :key="key">
            <td class="text-center">{{ article.title }}</td>
            <td class="text-center">{{ article.author }}</td>
            <td class="text-center">{{ article.category }}</td>
            <td class="text-center">{{ article.updated_time }}</td>
            <td class="text-center">
              <button
                type="button"
                class="btn btn-sm btn-warning"
                v-on:click="modalArticle(key)"
              >view</button>
              <button class="btn btn-sm btn-primary" v-on:click="modaleditArticle(key)">edit</button>
              <button class="btn btn-sm btn-danger" v-on:click="deletemodal(key)">delete</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>

    <!-- modal view -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="view"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewLabel">
              <b>Title :</b>
              {{Viewtitle}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <img :src="Viewphoto" alt="image" class="img-thumbnail rounded mx-auto d-block" />
            <hr />
            {{Viewtext}}
            <br />
            <hr />
            {{Viewphoto}}
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>

    <!-- modal edit -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="edit"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">
              <b>Title:</b>
              {{edittitle}}
            </h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <form>
              <label>Title</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditTitle}"
                v-model="edittitle"
                placeholder="update title"
              />
              <br />

              <div class="form-row">
                <div class="col-md-4">
                  <label>Author</label>
                  <input
                    type="text"
                    class="form-control"
                    :class="{'is-valid': valEditAuthor}"
                    v-model="editauthor"
                    placeholder="update author"
                  />
                </div>

                <div class="col-md-4">
                  <label>Category</label>
                  <select class="form-control" v-model="editcategory">
                    <option>General Health</option>
                    <option>Nutrition</option>
                  </select>
                </div>

                <div class="col-md-4">
                  <label>Update Time</label>
                  <span class="form-control">{{ date | moment }}</span>
                  <br />
                </div>
              </div>
              <label>Edit Link</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditUrl}"
                v-model="editlink"
                placeholder="update link"
              />
              <br />
              <label>Image Url</label>
              <input
                type="text"
                class="form-control"
                :class="{'is-valid': valEditPhoto}"
                v-model="photolink"
                placeholder="update link"
              />
              <br />
              <label>Update Text</label>
              <textarea
                class="form-control"
                rows="10"
                :class="{'is-valid': valEditText}"
                v-model="edittext"
              ></textarea>
            </form>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Cancel</button>
            <button type="button" class="btn btn-primary" @click="editArticle">Save changes</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Delete modal -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="delete"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Edit User</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>apakah anda ingin menghapus data ?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-sm btn-secondary" data-dismiss="modal">cancle</button>
            <button type="button" class="btn-sm btn-danger" @click="deleteArticle">Delete Data</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";
import moment from "moment";

export default {
  name: "Articles",

  data() {
    return {
      title: "",
      author: "",
      category: "General Health",
      updated_time: null,
      link: "",
      text: "",
      photo: "",

      date: {},

      articles: [],
      articlesData: null,

      Viewtitle: null,
      Viewauthor: null,
      Viewcategory: null,
      Viewupdated_time: null,
      Viewlink: null,
      Viewtext: null,
      Viewphoto: null,

      editlink: [],
      editcategory: [],
      editauthor: [],
      edittitle: [],
      editupdated_time: [],
      edittext: [],
      photolink: []
    };
  },

  computed: {
    // ------------------------ validasi add --------------------------
    valTitle() {
      if (this.title.length === 0) {
        return false;
      }
      return true;
    },
    valAuthor() {
      if (this.author.length === 0) {
        return false;
      }
      return true;
    },
    valUrl() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );

      if (pattern.test(this.link)) {
        return true;
      }
      return false;
    },
    valPhoto() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );

      if (pattern.test(this.photo)) {
        return true;
      }
      return false;
    },
    valText() {
      if (this.text.length === 0 || this.text.length < 20) {
        return false;
      }
      return true;
    },

    // ------------------------validasi edit---------------------------
    valEditTitle() {
      if (this.edittitle.length === 0) {
        return false;
      }
      return true;
    },
    valEditAuthor() {
      if (this.editauthor.length === 0) {
        return false;
      }
      return true;
    },
    valEditUrl() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );

      if (pattern.test(this.editlink)) {
        return true;
      }
      return false;
    },
    valEditPhoto() {
      var pattern = new RegExp(
        "^(https?:\\/\\/)?" + // protocol
        "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // domain name
        "((\\d{1,3}\\.){3}\\d{1,3}))" + // OR ip (v4) address
        "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // port and path
        "(\\?[;&a-z\\d%_.~+=-]*)?" + // query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      );

      if (pattern.test(this.photolink)) {
        return true;
      }
      return false;
    },
    valEditText() {
      if (this.text.editlength === 0 || this.text.editlength < 20) {
        return false;
      }
      return true;
    }
  },

  filters: {
    moment: function(date) {
      return moment(date).format("DD MMMM YYYY");
    }
  },

  methods: {
    addArticle() {
      this.articles.push(
        this.title,
        this.author,
        this.category,
        this.updated_time,
        this.link,
        this.text,
        this.photo
      );
      if (
        this.valTitle &&
        this.valAuthor &&
        this.valUrl &&
        this.valPhoto &&
        this.valText
      ) {
        firebase
          .database()
          .ref("articles")
          .push({
            title: this.title,
            author: this.author,
            category: this.category,
            updated_time: moment().format("DD MMMM YYYY"),
            link: this.link,
            text: this.text,
            photo: this.photo
          });
        this.title = "";
        this.author = "";
        this.category = null;
        this.updated_time;
        this.link = "";
        this.text = "";
        this.photo = "";
      }
    },

    deletemodal(articleKey) {
      $("#delete").modal("show");
      this.editKey = articleKey;
    },
    deleteArticle() {
      $("#delete").modal("hide");
      firebase
        .database()
        .ref("articles/" + this.editKey)
        .remove();
    },

    modaleditArticle(articleKey) {
      $("#edit").modal("show");
      firebase
        .database()
        .ref("articles/" + articleKey)
        .on("value", snapshot => {
          var res = snapshot.val();
          this.editlink = res.link;
          this.editcategory = res.category;
          this.editauthor = res.author;
          this.edittitle = res.title;
          this.editupdated_time = res.updated_time;
          this.edittext = res.text;
          this.photolink = res.photo;
          this.editKey = articleKey;
        });
    },
    editArticle() {
      if (
        this.valEditTitle &&
        this.valEditAuthor &&
        this.valEditUrl &&
        this.valEditPhoto &&
        this.valEditText
      ) {
        $("#edit").modal("hide");
        firebase
          .database()
          .ref("articles/" + this.editKey)
          .update({
            title: this.edittitle,
            author: this.editauthor,
            category: this.editcategory,
            updated_time: moment().format("DD MMMM YYYY"),
            link: this.editlink,
            text: this.edittext,
            photo: this.photolink
          });
      }
    },

    modalArticle(articleKey) {
      $("#view").modal("show");
      firebase
        .database()
        .ref("articles/" + articleKey)
        .on("value", snapshot => {
          var res = snapshot.val();
          this.Viewtitle = res.title;
          this.Viewauthor = res.author;
          this.Viewcategory = res.category;
          this.Viewupdated_time = res.updated_time;
          this.Viewlink = res.link;
          this.Viewtext = res.text;
          this.Viewphoto = res.photo;
        });
    },

    fetchFirebaseData() {
      firebase
        .database()
        .ref("articles")
        .on("value", snapshot => {
          this.articlesData = snapshot.val();
        });
    }
  },

  created() {
    this.fetchFirebaseData();
  }
};
</script>

