  <template>
  <div v-if="isAdmin">
    <div class="row">
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-warning">
                    <i class="ti-mobile"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Device</p>
                    {{devicedata}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <i class="ti-reload">Device</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-success">
                    <i class="ti-user"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Total User</p>
                    {{userdata}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <i class="ti-reload">Orang</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-success">
                    <i class="ti-user"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Total Doctor</p>
                    {{doctorData}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <i class="ti-reload">Orang</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-danger">
                    <i class="ti-pulse"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Log Checking</p>
                    {{logdata}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <i class="ti-reload">Pengecekan</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-6 col-xl-3">
        <div class="card">
          <div class="card-body">
            <div>
              <div class="row">
                <div class="col-5">
                  <div class="icon-big text-center icon-warning">
                    <i class="ti-server"></i>
                  </div>
                </div>
                <div class="col-7">
                  <div class="numbers">
                    <p>Avarage</p>
                    {{total}}{{atas}}-{{bawah}}
                  </div>
                </div>
              </div>
              <div>
                <hr />
                <div>
                  <i class="ti-reload">Orang</i>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Charts-->
    <div class="row">
      <div class="col-md-6 col-12">
        <chart-card
          v-if="isDataPerDeviceLoaded"
          title="ATM Sehat Statistics"
          sub-title="Total checking device in 2022"
          :chart-data="preferencesChart.data"
          :chart-options="preferencesChart.option"
          chart-type="Pie"
        >
          <span slot="footer">
            <i class="ti-timer"></i> Pesebaran pengecekan
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-warning"></i>
            {{legend1}}
            <br />
            <i class="fa fa-circle text-info"></i>
            {{legend2}}
            <br />
            <i class="fa fa-circle text-danger"></i>
            {{legend3 }}
          </div>
        </chart-card>
      </div>

      <div class="col-md-6 col-12">
        <chart-card
          v-if="isActivityDataLoaded"
          title="Check Traffic"
          sub-title="All products including Taxes"
          :chart-data="activityChart.data"
          :chart-options="activityChart.options"
        >
          <span slot="footer">
            <i class="ti-check"></i> Data information certified
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-warning"></i>
            {{data1}}
            <br />
            <i class="fa fa-circle text-info"></i>
            {{data2}}
            <br />
            <i class="fa fa-circle text-danger"></i>
            {{data3 }}
          </div>
        </chart-card>
      </div>

      <div class="col-12">
        <chart-card
          v-if="isDataPerHariLoaded"
          title="ATM Sehat Log Checking "
          sub-title="Days Checking in 2022"
          :chart-data="usersChart.data"
          :chart-options="usersChart.options"
        >
          <span slot="footer">
            <i class="ti-reload"></i> Data harian
          </span>
          <div slot="legend">
            <i class="fa fa-circle text-warning"></i>
            {{device1}}
            <i class="fa fa-circle text-info"></i>
            {{device2}}
            <i class="fa fa-circle text-danger"></i>
            {{device3}}
          </div>
        </chart-card>
      </div>
    </div>
  </div>
</template>
  <script>
import { StatsCard, ChartCard } from "@/components/index";
import Chartist from "chartist";
import firebase from "firebase";

export default {
  components: {
    ChartCard
  },
  /**
   * Chart data used to render stats, charts. Should be replaced with server data
   */
  data() {
    return {
      isAdmin: false,

      logdata: null,
      bawah: null,
      atas: null,
      devicedata: null,
      userdata: null,
      doctorData: null,
      myArray: null,
      dataPerDevice: [],

      legend1: null,
      legend2: null,
      legend3: null,

      isDataPerDeviceLoaded: false,
      isActivityDataLoaded: false,
      isDataPerHariLoaded: false,

      data1: null,
      data2: null,
      data3: null,

      device1: null,
      device2: null,
      device3: null,

      usersChart: {
        data: {
          labels: [
            "Senin",
            "Selasa",
            "Rabu",
            "Kamis",
            "Jumat",
            "Sabtu",
            "Minggu"
          ],
          series: []
        },
        options: {
          low: 0,
          // high: 200,
          showArea: false,
          height: "245px",
          axisX: {
            showGrid: true
          },
          lineSmooth: Chartist.Interpolation.simple({
            divisor: 3
          }),
          showLine: true,
          showPoint: true
        }
      },

      activityChart: {
        data: {
          labels: [
            "Jan",
            "Feb",
            "Mar",
            "Apr",
            "Mai",
            "Jun",
            "Jul",
            "Aug",
            "Sep",
            "Oct",
            "Nov",
            "Dec"
          ],
          series: []
        },
        options: {
          seriesBarDistance: 10,
          axisX: {
            showGrid: true
          },
          height: "245px"
        }
      },
      preferencesChart: {
        data: {
          labels: ["100%"],
          series: [100]
        },
        options: {}
      }
    };
  },

  computed: {
    total() {
      if (this.logdata % this.userdata == 0)
        return this.logdata / this.userdata;
      else {
        this.bawah = Math.ceil(this.logdata / this.userdata);
        this.atas = Math.floor(this.logdata / this.userdata);
      }
    }
  },

  created() {
    this.retrieveUser();
  },

  methods: {
    checking() {
      firebase
        .database()
        .ref("atm_sehat/log_checking")
        .once("value", snapshot => {
          this.logdata = snapshot.numChildren();
          var arr = snapshot.val();
          var res = [];
          var idNamePair = [];
          var counter = 0;
          var resMilis = [];
          Object.keys(arr).forEach(function(key) {
            res[arr[key].id_device] = Number.isNaN(res[arr[key].id_device])
              ? 1
              : res[arr[key].id_device] + 1;
            if (resMilis[arr[key].id_device] === undefined) {
              resMilis[arr[key].id_device] = [];
            }
            resMilis[arr[key].id_device].push(arr[key].milis);
            counter += 1;
            idNamePair[arr[key].id_device] = arr[key].device_name;
          });
          this.dataPerDevice = res;

          // console.log(res)
          var ser = [];
          var lab = [];
          var devicename = [];

          Object.keys(res).forEach(function(key) {
            var avrg = Math.ceil((res[key] / counter) * 100);
            ser.push(avrg);
            lab.push(avrg + "%") + " " + key;
            devicename.push({ id: key, total: avrg });
          });
          devicename.sort(function(a, b) {
            return b.total - a.total;
          });
          if (devicename.length < 2) {
            this.legend1 = devicename[0].total + "% : " + idNamePair[devicename[0].id];
          } else if (devicename.length < 3) {
            this.legend1 = devicename[0].total + "% : " + idNamePair[devicename[0].id];
            this.legend2 = devicename[1].total + "% : " + idNamePair[devicename[1].id];
          } else if (devicename.length > 2) {
            this.legend1 = devicename[0].total + "% : " + idNamePair[devicename[0].id];
            this.legend2 = devicename[1].total + "% : " + idNamePair[devicename[1].id];
            this.legend3 = devicename[2].total + "% : " + idNamePair[devicename[2].id];
            
          }
          this.preferencesChart.data.labels = lab;
          this.preferencesChart.data.series = ser;
          this.isDataPerDeviceLoaded = true;

          this.retrieveTraffic2(resMilis);
          this.userBehavior2(resMilis);
          // console.log(ser)
        });
    },
    retrieveTraffic(arrTraffic) {
      var res = [];
      Object.keys(arrTraffic).forEach(function(key) {
        if (res[key] === undefined) {
          res[key] = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
        }
        arrTraffic[key].forEach(getMonth);
        function getMonth(item) {
          var d = new Date(item);
          var n = d.getMonth();
          var y = d.getFullYear();
          if (y == 2022) {
            res[key][n] += 1;
          }
        }
      });

      for (var key in res) {
        // check if the property/key is defined in the object itself, not in parent
        this.activityChart.data.series.push(res[key]);
      }
      this.isActivityDataLoaded = true;
      var data = [];
      Object.keys(res).forEach(function(key) {
        var k = res[key];
        data[key] = key + " = " + k;
      });

      this.data1 = data.atmsehat01;
      this.data2 = data.DCCDfgdthfhdf;
      this.data3 = data.detos01;
    },

    retrieveTraffic2(arrTraffic) {
      var res = [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0];
      Object.keys(arrTraffic).forEach(function(key) {
        arrTraffic[key].forEach(getMonth);
        function getMonth(item) {
          var d = new Date(item);
          var n = d.getMonth();
          var y = d.getFullYear();
          if (y == 2022) {
            res[n] += 1;
          }
        }
      });
      this.activityChart.data.series.push(res);

      this.isActivityDataLoaded = true;
      var data = [];
      Object.keys(res).forEach(function(key) {
        var k = res[key];
        data[key] = "Bulan ke " + (Number(key) + 1) + " = " + k + " data";
      });

      this.data1 = data[9];
      this.data2 = data[10];
      this.data3 = data[11];
    },

    userBehavior(arrDay) {
      var res = [];
      Object.keys(arrDay).forEach(function(key) {
        if (res[key] === undefined) {
          res[key] = [0, 0, 0, 0, 0, 0, 0];
        }
        arrDay[key].forEach(getDay);
        function getDay(item) {
          var d = new Date(item);
          var n = d.getDay();
          var y = d.getFullYear();
          if (y == 2022) {
            res[key][n] += 1;
          }
        }
      });
      var ser = [];
      var dayData = [];
      Object.keys(res).forEach(function(key) {
        var dataharian = res[key];
        ser.push(dataharian);

        var k = res[key];
        dayData[key] = key + " = " + k;
      });
      this.usersChart.data.series = ser;
      this.isDataPerHariLoaded = true;

      // console.log(this.usersChart.data.series )

      this.device1 = dayData.atmsehat01;
      this.device2 = dayData.DCCDfgdthfhdf;
      this.device3 = dayData.detos01;
    },
    userBehavior2(arrDay) {
      var res = [0, 0, 0, 0, 0, 0, 0];
      Object.keys(arrDay).forEach(function(key) {
        arrDay[key].forEach(getDay);
        function getDay(item) {
          var d = new Date(item);
          var n = d.getDay();
          var y = d.getFullYear();
          if (y == 2022) {
            res[n] += 1;
          }
        }
      });
      var ser = [];
      ser.push(res);
      this.usersChart.data.series = ser;
      this.isDataPerHariLoaded = true;
    },
    device() {
      firebase
        .database()
        .ref("atm_sehat/device")
        .once("value", snapshot => {
          this.devicedata = snapshot.numChildren();
        });
    },

    user() {
      firebase
        .database()
        .ref("user")
        .once("value", snapshot => {
          this.userdata = snapshot.numChildren();
        });
    },

    doctor(){
      firebase
        .database()
        .ref("doctor")
        .once("value", snapshot => {
          this.doctorData = snapshot.numChildren();
        });
    },

    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
            if(this.isAdmin!=null && this.isAdmin){
              this.checking();
              this.device();
              this.user();
              this.doctor();
            }else{
              this.isAdmin = false;
            }
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    }
  }
};
</script>


<style>
</style>
