<template>
  <div class="container-fluid">
    <div class="card">
      <h3 class="card-header">Management Users</h3>
      <div class="card-body">
        <form>
          <div class="form-row">
            <div class="col">
              <input
                type="email"
                aria-describedby="emailHelp"
                class="form-control"
                :class="{ 'is-valid': valEmail }"
                v-model="email"
                placeholder="enter Email"
              />
            </div>
            <div class="col">
              <select class="form-control" v-model="status">
                <option disabled value>status</option>
                <option selected="Admin" value="Admin">Admin</option>
                <option selected="Faskes" value="Faskes">Fasilitas Kesehatan</option>
              </select>
            </div>
            <button
              id="button"
              class="btn btn-success"
              type="button"
              @click="addUserAccess"
            >add access</button>
          </div>
        </form>
      </div>
    </div>

    <card>
      <div>
        <table class="table table-striped table-bordered table-hover">
          <thead class="table-info">
            <tr>
              <th class="text-center">Phone</th>
              <th class="text-center">Name</th>
              <th class="text-center">Email</th>
              <th class="text-center">Status</th>
              <th class="text-center">Role</th>
              <th class="text-center">Aksi</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(user,key) in users" :key="key">
              <td class="text-center">{{ user.nomor_telepon }}</td>
              <td class="text-center">{{ user.nama }}</td>
              <td class="text-center">{{ user.email }}</td>
              <td class="text-center">{{ user.web_admin }}</td>
              <td class="text-center">{{ user.role }}</td>
              <td class="text-center">
                <button
                  v-if="!user.web_superadmin"
                  id="btndel"
                  class="btn btn-danger btn-sm"
                  @click="revokeAccess(user.id)"
                >revoke access</button>
                <span v-if="user.web_superadmin">Super Admin</span>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
    </card>

    <!-- Small modal -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="revokeAccess"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Revoke Access</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>apakah anda ingin membatalkan izin akses user?</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-sm btn-secondary" data-dismiss="modal">cancel</button>
            <button type="button" class="btn-sm btn-danger" @click="removeUserAccess">Revoke Access</button>
          </div>
        </div>
      </div>
    </div>

    <!-- Small modal not found -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="notification"
      tabindex="-1"
      role="dialog"
      aria-labelledby="editLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-sm" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="editLabel">Notification</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <p>{{this.notification_content}}</p>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn-sm btn-secondary" data-dismiss="modal">cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import firebase from "firebase";
import $ from "jquery";
import moment from "moment";

export default {
  name: "users",

  data() {
    return {
      id: null,
      email: "",
      status: "Admin",
      users: [],
      list_email_non_admin: [],
      notification_content: ""
    };
  },

  mounted() {
    this.id = "ID-" + Math.floor(Math.random() * 10000);
  },

  computed: {
    // ------- validasi add --------
    valEmail() {
      var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (re.test(this.email)) {
        return true;
      }
      return false;
    },
    button1() {
      var ref = firebase
        .database()
        .ref("db_admin/")
        .orderByChild("status")
        .equalTo("SUadmin");
      if (ref === ref) {
        return $("#btndel").hide();
      }
    }
  },

  methods: {
    addUserAccess() {
      var emailFound = false;
      var list_user = this.list_email_non_admin;
      var userKey = "";
      var email = this.email;
      list_user.forEach(function(element) {
        if (email == element.email) {
          userKey = element.id;
          emailFound = true;
        }
      });

      $("#notification").modal("show");
      
      if (emailFound) {
        if(this.status==="Admin"){
          firebase
            .database()
            .ref("user/" + userKey + "/")
            .update({
              web_admin: true
            });
        }else{//Faskes
          firebase
            .database()
            .ref("user/" + userKey + "/")
            .update({
              web_faskes: true
            });
        }
        
        this.notification_content = this.email + " sudah ditambahkan, refresh halaman!";
      } else {
        this.notification_content = this.email + " tidak ditemukan";
      }
    },

    revokeAccess(userKey) {
      $("#revokeAccess").modal("show");
      this.editKey = userKey;
    },

    removeUserAccess() {
      $("#revokeAccess").modal("hide");
      firebase
        .database()
        .ref("user/" + this.editKey + "/web_admin")
        .remove();
      firebase
        .database()
        .ref("user/" + this.editKey + "/web_faskes")
        .remove();

      $("#notification").modal("show");
      this.notification_content = this.email + " sudah direvoke, refresh halaman!";
      // firebase.database().ref('user/'+this.editKey+'/web_superadmin').remove();
    },

    editmodal(userKey) {
      $("#edit").modal("show");
      firebase
        .database()
        .ref("db_admin/" + userKey)
        .once("value", snapshot => {
          var ok = snapshot.val();
          this.editId = ok.id;
          this.editName = ok.name;
          this.editEmail = ok.email;
          this.editPassword = ok.password;
          this.editStatus = ok.status;
          this.createdAT = ok.createdAT;
          this.editKey = userKey;
        });
    },

    editUser() {
      if (this.valEditName && this.valEditPassword) {
        $("#edit").modal("hide");
        firebase
          .database()
          .ref("db_admin/" + this.editKey)
          .update({
            id: this.editId,
            name: this.editName,
            email: this.editEmail,
            password: this.editPassword,
            status: this.editStatus,
            createdAT: moment().format("LLLL")
          });
        this.editId = null;
        this.editName = "";
        this.editEmail = null;
        this.editPassword = "";
        this.editStatus = null;
        this.createdAT;
      }
    },

    fetchFirebaseData() {
      var list_admin = [];
      var users_non_admin = [];
      var userFaskes = [];
      firebase
        .database()
        .ref("user")
        .once("value", snapshot => {
          var list_user = snapshot.val();
          Object.keys(list_user).forEach(function(key) {
            if (list_user[key].web_admin) {
              list_admin.push({
                id: key,
                nomor_telepon: list_user[key].nomor_telepon,
                nama: list_user[key].nama,
                web_admin: list_user[key].web_admin,
                email: list_user[key].email,
                role: "Admin",
                web_superadmin: list_user[key].web_superadmin
              });
            }else if(list_user[key].web_faskes){
              list_admin.push({
                id: key,
                nomor_telepon: list_user[key].nomor_telepon,
                nama: list_user[key].nama,
                web_admin: list_user[key].web_admin,
                email: list_user[key].email,
                role: "Faskes",
                web_superadmin: list_user[key].web_superadmin
              });
            }
            else {
              users_non_admin.push({
                email: list_user[key].email,
                id: key
              });
            }
          });
          this.users = list_admin;
          this.list_email_non_admin = users_non_admin;
        });
    }
  },
  created() {
    this.fetchFirebaseData();
  }
};
</script>
