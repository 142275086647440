  <template>
  <div>
    <div class="card">
      <div class="card-header">
        <select
          v-model="data"
          @change="readdata1"
          class="form-control form-control-lg"
          aria-placeholder="Select Device"
        >
          <option value disabled selected hidden>Choose device</option>
          <option v-for="(device,key) in list_devices" :key="key" :value="key">{{device.name}}</option>
        </select>
      </div>
      <div class="card-body" v-show="showCategoryFilter">
        Filter Data
        <select
          v-model="categoryData"
          @change="selectCategory"
          class="form-control form-control-lg"
          aria-placeholder="Select Device"
        >
          <option value disabled selected hidden>Choose category</option>
          <option value="">All</option>
          <option v-for="(category) in list_category" :key="category" :value="category">{{category}}</option>
        </select>
      </div>
      <div class="card-body" v-show="showPagination">
        <div class="icon-container" @click="previousPage">
          <span class="ti-angle-double-left"></span>
          <span class="icon-name"> Halaman: {{currentPage+1}}. Per halaman : {{maxRow}} </span>
        </div>
        <div class="icon-container align-right" @click="nextPage">
          <span class="ti-angle-double-right"></span>
        </div>
      </div>
      <div class="card-body">
        <div class="table-responsive">
          <table class="table table-bordered table-hover">
            <thead class="table-info">
              <tr>
                <th class="text-center">No.</th>
                <th class="text-center">Time</th>
                <th class="text-center">ID User</th>
                <th class="text-center">Data Type</th>
                <th class="text-center">Data Action</th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(data,key, index) in logcheckingShow" :key="key">
                <td class="text-center">{{currentPage*maxRow+index+1 }}</td>
                <td class="text-center">{{milisToDate(data.milis)}}</td>
                <td class="text-center">{{convertUserId(data.user_id)}}</td>
                <td class="text-center">{{JSON.parse(data.data).sensorName}}</td>
                <td class="text-center">
                  <button
                    type="button"
                    class="btn btn-sm btn-primary"
                    @click="modalview(key)"
                  >View data</button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </div>

    <!-- modal edit -->
    <div
      class="modal fade"
      data-backdrop="false"
      id="view"
      tabindex="-1"
      role="dialog"
      aria-labelledby="viewLabel"
      aria-hidden="true"
    >
      <div class="modal-dialog" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewLabel">User's Data</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <table class="table table-bordered table-hover">
              <tr>
                <td class="text-right">Sensor Name</td>
                <td>{{healthoverview.sensorName}}</td>
              </tr>
              <tr>
                <td class="text-right">Description</td>
                <td >{{healthoverview.description}}</td>
              </tr>
              <tr v-for="(data,key) in healthoverview.value" :key="key">
                  <td class="text-right">{{data.name}}</td>
                  <td>{{data.value+" "+data.unit}}</td>
              </tr>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
  <script>
import firebase from "firebase";
import $ from "jquery";

export default {
  data() {
    return {
      isAdmin: false,
      isFaskes: false,
      emailUser: "",
      logchecking: null,
      logcheckingShow: null,
      data: "",

      maxRow: 20,
      currentPage: 0,
      paginationData: {},
      showPagination: false,
      totalData: 0,

      categoryData: "",
      showCategoryFilter: false,
      list_category:["Suhu", "OxiMeter","Scale","Blood Pressure Monitor","Glucose and Cholesterol","Asam Urat","Stunting Score","Height"],

      healthoverview: "",
      list_devices: []
    };
  },
  created() {
    // this.readalldata();
    // this.readdata1();
    this.retrieveUser();
  },

  methods: {
    retrieveUser(){
      const currentUser = firebase.auth().currentUser;
      if(currentUser==null){
        return;
      }
      this.emailUser = currentUser.email;
      firebase
        .database()
        .ref("user/"+this.encodeEmail(currentUser.email))
        .once("value", snapshot => {
            var res = snapshot.val();   
            this.isAdmin = res.web_admin;
            this.isFaskes = res.web_faskes;
            this.fetchFirebaseData(this.isFaskes);
        });
    },
    encodeEmail(email){
      return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
    },
    fetchFirebaseData(isFaskes) {
      if(isFaskes!=null && isFaskes){
        firebase
          .database()
          .ref("atm_sehat/device")
          .orderByChild("faskesEmail")
          .equalTo(this.emailUser)
          .on("value", snapshot => {
            this.list_devices = snapshot.val();
          });
      }else{
        firebase
          .database()
          .ref("atm_sehat/device")
          .on("value", snapshot => {
            this.list_devices = snapshot.val();
          });
      }
    },
    readdata1(event) {
      var ref = firebase.database().ref("atm_sehat/log_checking");
      ref
        .orderByChild("id_device")
        .equalTo(this.data)
        .once("value", snapshot => {
          this.logchecking = snapshot.val();
          this.totalData = this.getMapSize(this.logchecking)
          this.setPaginationData();
          this.showCategoryFilter = true;
          this.showPagination = true;
          this.categoryData="";
          // console.log(this.logchecking)
        });
    },
    milisToDate(milis) {
      var time = new Date(milis);
      const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric' };
      return time.toLocaleString('id-ID', options);
    },
    convertUserId(userId) {
      var res = null;
      if(userId!==undefined){
        var name = userId.replaceAll("X40", "@");
        res = name.replaceAll("X2E",".");
      }
      return res;
    },
    selectCategory() {
      if(this.categoryData!=null && this.categoryData.length!=0){
        if(this.logchecking!=null && this.logchecking.length!=0){
          this.logcheckingShow = {};
          for (let key in this.logchecking) {
            var sensorName = JSON.parse(this.logchecking[key].data).sensorName
            sensorName = sensorName.toLowerCase();
            
            var searchInputLower = this.categoryData.toLowerCase();
            if(sensorName.includes(searchInputLower)){
              this.logcheckingShow[key] = this.logchecking[key];
            }
          }
          this.showPagination = false;
          this.currentPage = 0;
          return;
        }
      }
      this.setPaginationData();
      this.showPagination = true;
      this.setPaginationData();
    },
    modalview(key) {
      $("#view").modal("show");
      firebase
        .database()
        .ref("atm_sehat/log_checking/" + key)
        .on("value", snapshot => {
          var res = snapshot.val();
          var datahealth = JSON.parse(res.data);
          this.healthoverview = datahealth;
        });
    },
    getMapSize(x) {
        var len = 0;
        for (var count in x) {
                len++;
        }

        return len;
    },
    previousPage(){
      var page = this.currentPage;
      if(page<1){
        page = 0;
      }else{
        page -= 1;
      }
      this.currentPage = page;
      this.logcheckingShow = this.paginationData[page];
    },
    nextPage(){
      var page = this.currentPage;
      var size = this.totalData
      if((page+1)*this.maxRow<size){
        page+=1;
      }
      this.currentPage=page;
      this.logcheckingShow = this.paginationData[page];
    },
    setPaginationData(){
      var index = 0;
      var counter = 0;
      var maxRowData = this.maxRow;
      var data = this.logchecking;
      var dataPerPage = {};
      var result = {};
      for (var key in data) {
        if(counter<(index+1)*maxRowData){
          dataPerPage[key] = data[key];
        }
        if(counter==this.totalData-1 || counter>=(index+1)*maxRowData){
          result[index] = dataPerPage;
          index++;
          dataPerPage = {}
          dataPerPage[key] = data[key]; 
        }
        counter++;
      }
      this.paginationData = result;
      this.logcheckingShow = this.paginationData[0];
    }
  }
};
</script>


<style>
</style>
