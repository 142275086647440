import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import firebase from "firebase";

Vue.use(VueRouter);

// configure router
const router = new VueRouter({
  routes, // short for routes: routes
  linkActiveClass: "active"
});
const encodeEmail = (email) => {
  return String(email).toLowerCase().replaceAll(".","X2E").replaceAll("@","X40");
};

router.beforeEach((to, from, next) => {
  const currentUser = firebase.auth().currentUser;
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) next('login');
  else if (!requiresAuth && currentUser) next('dashboard');
  else {
    var adminOnlyPath = ["mnguser","medicine","article","banner"];
    
    if(currentUser){
      firebase
      .database()
      .ref("user/"+encodeEmail(currentUser.email))
      .once("value", snapshot => {
          var res = snapshot.val();   
          var isAdmin = res.web_admin;
          //console.log("to: "+to.name);
          if(!isAdmin && adminOnlyPath.includes(to.name)){
            next('dashboard');
          }else{
            next();
          }
      });
    }else{
      next();
    }

  }
});


export default router;
